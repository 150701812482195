import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'inicio',
    component: () => import('../views/inicio.vue'),
    meta:{
      title:"Transporte - Inicio"
    }
  },
  {
    path: '/utilidadesGenerales',
    name: 'utilidadesGenerales',
    component: () => import('../views/utilidadesGenerales.vue'),
    meta:{
      title:"Transporte - Utilidades Generales"
    }
  },
  {
    path: '/utilidadesVehiculos',
    name: 'utilidadesVehiculos',
    component: () => import('../views/utilidadesVehiculos.vue'),
    meta:{
      title:"Transporte - Utilidades Vehiculos"
    }
  },
  {
    path:'/login',
    name:'login',
    component:() => import('../views/login.vue'),
    meta: {
      hideNavbar: true,
      title:"Transporte - Login"
     }
  },
  {
    path:'/consultarViajes',
    name:'consultarViajes',
    component:() => import('../views/consultarViajes.vue'),
    meta:{
      title:"Transporte - Consultar viajes"
    }
  },
  {
    path:'/crearViajes',
    name:'crearViajes',
    component:() => import('../views/crearViajes.vue'),
    meta:{
      title:"Transporte - Crear viaje"
    }
  },
  {
    path:'/crearTerceros',
    name:'crearTerceros',
    component:() => import('../views/crearTerceros.vue'),
    meta:{
      title:"Transporte - crear Terceros"
    }
  },
  {
    path:'/consultarTerceros',
    name:'consultarTerceros',
    component:() => import('../views/consultarTerceros.vue'),
    meta:{
      title:"Transporte - consultar Terceros"
    }
  },
  {
    path:'/crearConceptos',
    name:'crearConceptos',
    component:() => import('../views/crearConceptos.vue'),
    meta:{
      title:"Transporte - crear conceptos"
    }
  },
  {
    path:'/crearProveedor',
    name:'crearProveedor',
    component:() => import('../views/crearProveedor.vue'),
    meta:{
      title:"Transporte - crear proveedor"
    }
  },
  {
    path:'/consultarEliminarProveedores',
    name:'consultarEliminarProveedores',
    component:() => import('../views/consultarEliminarProveedores.vue'),
    meta:{
      title:"Transporte - consultar proveedores"
    }
  },{
    path:'/crearEditarCompras',
    name:'crearEditarCompras',
    component:() => import('../views/crearEditarCompras.vue'),
    meta:{
      title:"Transporte - Crear editar compra"
    }
  },{
    path:'/generarExcelComprasConceptos',
    name:'generarExcelComprasConceptos',
    component:() => import('../views/generarExcelComprasConceptos.vue'),
    meta:{
      title:"Transporte - Crear editar compra"
    }
  },{
    path:'/consultarCompras',
    name:'consultarCompras',
    component:() => import('../views/consultarCompras.vue'),
    meta:{
      title:"Transporte - Crear editar compra"
    }
  },{
    path:'/crearGastosViajes',
    name:'crearGastosViajes',
    component:() => import('../views/crearGastosViajes.vue'),
    meta:{
      title:"Transporte - Crear gastos para viajes"
    }
  },{
    path:'/copiaSeguridad',
    name:'copiaSeguridad',
    component:() => import('../views/copiaSeguridad.vue'),
    meta:{
      title:"Transporte - Copia de seguridad"
    }
  },


  
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) =>{
  document.title = `${to.meta.title}`;
  next();
})

export default router
