import { createStore } from "vuex";
import { auth } from "../main";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { doc } from "firebase/firestore";
//import VuexPersistence from 'vuex-persist';
import { db } from "../main.js";
import { collection, getDocs, query, limit, orderBy, getDoc } from "firebase/firestore";
import SecureLS from "secure-ls";
import createPersistedState from "vuex-persistedstate";

const ls = new SecureLS({
  isCompression: true,
});

const storeaAutenticacion = createStore({
  plugins: [
    createPersistedState({
      //variables que quiero que permanezcan incluso si se recarga la pagina
      paths: [
        "user",
        "permisosUsuario",
        "ciudades",
        "terceros",
        "empresas",
        "vehiculos",
        "conductores",
        "gastos",
        "tiposConcepto",
        "tiposProveedores",
        "tiposFormasDePago"
      ],
      overwrite: true,
      storage: {
        getItem: (key) => {
          return ls.get(key);
        },
        setItem: (key, value) => {
          ls.set(key, value);
        },
        removeItem: (key) => {
          ls.remove(key);
        },
      },
    }),
  ],
  state: {
    //The user state will initially be null. After login, this state will be updated
    user: "ninguno",
    permisosUsuario: { consultarReportes: false },
    ciudades: "ninguna",
    gastos: "ninguno",
    ultimoDocumento: "ninguno",
    tiposConceptos: "ninguno",
    proveedores: []
  },
  mutations: {
    setEstado(state, payload) {
      //console.log("seteando estado " + payload.coleccion + ", datos => ", payload.datos);
      state[payload.coleccion] = payload.datos;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setPermisosUsuario(state, payload) {
      state.permisosUsuario = payload;
    },
    setCiudades(state, payload) {
      state.ciudades = payload;
    },
    setTerceros(state, payload) {
      state.terceros = payload;
    },
    setConductores(state, payload) {
      state.conductores = payload;
    },
    setGastos(state, payload) {
      state.gastos = payload;
    },
    setUltimoDocumento(state, payload) {
      state.ultimoDocumento = payload;
    },
  },
  actions: {
    async login(context, { email, password }) {
      const response = await signInWithEmailAndPassword(auth, email, password);
      if (response) {
        //console.log(response.user.email);


      } else {
        throw new Error("login failed");
      }
    },
    async actualizarPermisosUsuario(context, permisos) {
      context.commit("setPermisosUsuario", permisos);

    },
    async actualizarUsuarioLogueado(context, usuario) {
      context.commit("setUser", usuario);
    },
    async consultarDatosDefecto(context, idDoc) {
      //console.log("se consultará la coleccion: "+coleccion);
      const docRef = doc(db, "datosDefecto", idDoc);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        let arrDatosDefectos = [];
        let objDatoDefecto = {}
        for (let clave in docSnap.data()) {
          objDatoDefecto = docSnap.data()[clave];
          arrDatosDefectos.push(objDatoDefecto);
          objDatoDefecto = {}
        }
        //realmente doc es un doc de la coleccion datosDefecto pero aca se iran como si se 
        //hubiese consultado una coleccion
        context.commit("setEstado", { coleccion: idDoc, datos: arrDatosDefectos });
      } else {
        alert(
          "No existe el documento " + doc + " en la coleccion datosDefecto"
        );
      }

    },
    async logout(context) {
      await signOut(auth);
      context.commit("setUser", "ninguno");
      context.commit("setPermisosUsuario", "ninguno");
    },
    async consultarCiudades(context) {
      let ciudades = [];
      const q = query(collection(db, "ciudades"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        ciudades.push({ ...doc.data(), id: doc.id });
      });
      context.commit("setCiudades", ciudades);
    },
    async consultarGastos(context) {
      let gastos = [];
      const q = query(collection(db, "gastos"));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        for (let clave in doc.data()) {
          //console.log("clave iterada "+clave+" en el doc de id: "+doc.id);
          //console.log("gasto",doc.data()[clave])
          if (clave != "id") {
            gastos.push(doc.data()[clave])
          }
        }
      });
      //console.log("gastos",gastos)
      context.commit("setGastos", gastos);
    },
    async consultarUltimoDocumento(context, coleccion) {
      //console.log("coleccion:"+coleccion);
      const q = query(
        collection(db, coleccion),
        limit(1),
        orderBy("id", "desc")
      );
      const el = await getDocs(q);
      const ultimoDocumento = el.docs.map((doc) => ({
        ...doc.data(),
      }));
      context.commit("setUltimoDocumento", ultimoDocumento);
    },
  },
  getters: {
    obtenerPermisosUsuario: (state) => {
      console.log(state)
      return state.permisosUsuario;
    },
    obtenerProveedores: (state) => {
      return state.proveedores;
    }
    /*
        obtenerUsuarioLogueado (state) {
            console.log("estado usuario en getter:");
            //console.log(state.user.email);
            return state.user;
            //return state.user;  
        }*/
  } /*, plugins:[new VuexPersistence({storage: window.localStorage}).plugin]*/,
});
// export the store
export default storeaAutenticacion;
