<script>
import { /*reactive,*/ onBeforeMount, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import firebase from 'firebase/compat/app';
import { useStore } from 'vuex';
//import { elimDocPorId } from './scripts/firestore.eliminar.js'

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    onBeforeMount(() => {
      //const user = firebase.auth().currentUser;
      //console.log(user.email);
      //alert("se ejecuto onBeforeMount en App.vue");

      firebase.auth().onAuthStateChanged((user) => {
        if (!user) {
          console.log("APP.vue = no hay usuario logueado");
          router.replace('/login');
        } else if (route.path == "/login") {
          router.replace('/');
        }
      });

      //tuve que poner esto asi por que ahora firebase no permite eliminar algo
      //console.log("se eliminará")
      //elimDocPorId("compras", 256)
    });

    const cerrarSesion = () => {
      store.dispatch('logout');
    }

    //esto lo pongo aca para que no me de error de permisos por si ejecuto el servidor de desarrollo y hay una sesion abierta pero no lee estas claves
    /*let permisosUsuario = reactive({
      consultarReportes: true
    })*/

    return {
      cerrarSesion,
      usuarioLogueado: computed(() => store.state.user.email),
      permisosUsuario: computed(() => store.state.permisosUsuario),
      //permisosUsuario,
      count: 3500
    }
  }
}

</script>

<template>
  <link rel="icon" type="image/png" href="./assets/favicon.ico" />
  <div class="header" v-if="!usuarioLogueado">
    <img src="./assets/logo.png" class="logo" />
    <br>
    <h3>{{ usuarioLogueado }}</h3>
  </div>
  <nav v-if="!$route.meta.hideNavbar" class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <img src="./assets/logo.png" class="logo" width="140" height="50" />
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link class="nav-link" aria-current="page" to="/">Inicio</router-link>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              Viajes
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <router-link class="dropdown-item" to="/crearViajes">Crear/Editar</router-link>
              </li>
              <li>
                <router-link class="dropdown-item" to="/consultarViajes">Consultar</router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">Terceros</a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <router-link class="dropdown-item" to="/crearTerceros">Crear</router-link>
              </li>
              <li>
                <router-link class="dropdown-item" to="/consultarTerceros">Consultar</router-link>
              </li>
            </ul>
          </li>

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              Proveedores
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><router-link class="dropdown-item" to="/crearProveedor">Crear</router-link></li>
              <li><router-link class="dropdown-item" to="/consultarEliminarProveedores">Consultar/Eliminar </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              Compras
            </a>
            <ul class="dropdown-menu" aria-labelledby="chrnavbarDropdown">
              <li><router-link class="dropdown-item" to="/crearEditarCompras">Crear/Editar</router-link></li>
              <li><router-link class="dropdown-item" to="/consultarCompras">Consultar</router-link></li>
            </ul>
          </li>

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">Reportes</a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><router-link class="dropdown-item" to="/generarExcelComprasConceptos">Excel gastos viajes y
                  compras</router-link></li>
            </ul>
          </li>
          <li v-if="permisosUsuario.consultarReportes" class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">Utilidades</a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><router-link class="dropdown-item" to="/utilidadesGenerales">Generales (Bruta, sin
                  compras)</router-link></li>
              <li><router-link class="dropdown-item" to="/utilidadesVehiculos">Vehiculos</router-link></li>
            </ul>
          </li>

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              Datos
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <router-link class="dropdown-item" to="/crearConceptos">Crear conceptos para compras</router-link>
                <router-link class="dropdown-item" to="/crearGastosViajes">Crear gastos para viajes</router-link>
                <router-link class="dropdown-item" to="/copiaSeguridad">Copia de seguridad</router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <button class="nav-link btn btn-danger" @click="cerrarSesion" aria-current="page">Cerrar Sesion</button>
          </li>
        </ul>
        <span class="navbar-text">
          TRANSPORTE
        </span>
      </div>
    </div>
  </nav>
  <router-view />
</template>
<style lang="scss">
/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v139/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  /*-moz-font-feature-settings: 'liga';*/
  -moz-osx-font-smoothing: grayscale;
  vertical-align: -6px;
  /*border: solid 1px #ccc;*/
  /*margin: 0px 0px 0px 0px;*/
}


.header {
  overflow: hidden;
  padding: 20px 20px;
  background: #ffed00;
  overflow: hidden;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.5);
  padding-bottom: 30px;
  /*text-align: center;*/
}

body {
  background: #ffffff;
  color: #2c3e50;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

a {
  color: inherit;
}
</style>

