const firebaseConfig = {
    apiKey: "AIzaSyDQ_PhB2uJ0n77o-5RWoArwahnCi3E_PfU",
    authDomain: "dulcesita---transporte.firebaseapp.com",
    projectId: "dulcesita---transporte",
    storageBucket: "dulcesita---transporte.appspot.com",
    messagingSenderId: "792260389132",
    appId: "1:792260389132:web:2a6b9e64e089bcd6808129",
    measurementId: "G-4SYMSS6MP9"
  };

export default firebaseConfig;