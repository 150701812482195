import "bootstrap/dist/css/bootstrap.min.css"
import { createApp } from 'vue'
//import Vue from 'vue'
import App from './App.vue'
import router from './router'
import firebase from 'firebase/compat/app';
import Swal from 'sweetalert2';
import storeaAutenticacion from './store/index';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'vue-select/dist/vue-select.css';
import firebaseConfig from "/configuraciones";
// Use this to initialize the firebase App
const firebaseApp = firebase.initializeApp(firebaseConfig);
// Use these for db & auth
const db = firebaseApp.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
import ProgressBar from "./components/ProgressBar"

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
//import '@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss';

//import '@vuepic/vue-datepicker/dist/main.css';

export { auth, db, storage, Swal, ProgressBar };
import vSelect from 'vue-select';

const app = createApp(App);
app.component('v-select',vSelect);
app.component('progressBar',ProgressBar);
app.component('Datepicker', Datepicker);

app.use(router);
app.use(storeaAutenticacion);
app.mount('#app');
import "bootstrap/dist/js/bootstrap.js"